import React, { useState } from 'react';
import classNames from 'classnames';

import FilterVentures from '../FilterVentures';
import ContentFeed from '../ContentFeed';
import Reveal from '../Reveal';
import Image from '../Image';
import RenderStaticHTML from '../RenderStaticHTML';
import Link from '../Link';

const nsBase = 'component';
const ns = `${ nsBase }-all-ventures`;

const ventureItemView = ({ item }) => {
	return (
		<Reveal>
			<div className={`${ ns }__list-item`}>
				<div className={`${ ns }__list-item-image`}>
					<Link href={item.url} target={'_blank'}>
						<Image {...item.image} />
					</Link>
				</div>
				<div className={`${ ns }__list-item-name`}>
					<Link href={item.url} target={'_blank'}>
						{item.name}
					</Link>
					{item.name2 && (
						<div className={`${ ns }__list-item-name-2`}>
							{item.name2}
						</div>
					)}
				</div>
				<div className={`${ ns }__list-item-content`}>
					{item.secondColumnLines && item.secondColumnLines.map((line) => {
						return <RenderStaticHTML key={line} html={line} />;
					})}
				</div>
			</div>
		</Reveal>
	);
};

const AllVentures = ({ ventures }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	// array of items to render
	const [filteredItems, setFilteredItems] = useState([...ventures]);
	const [filterTransition, setFilterTransition] = useState(false);

	const handleFilteredItems = (items) => {
		if (items !== filteredItems) {
			setFilterTransition(true);
			setTimeout(() => {
				setFilteredItems(items);
				setFilterTransition(false);
			}, 750);
		}
	};

	const filterTransitionClass = filterTransition ? 'filter-transition' : '';

	return (
		<div className={rootClassnames}>
			<FilterVentures
				title={'All Ventures'}
				items={[...ventures]}
				filters={['industry', 'investment']}
				handleFilteredItems={handleFilteredItems}
			/>
			<div className={`${ ns }__items ${ filterTransitionClass }`}>
				<ContentFeed
					items={filteredItems}
					ItemView={ventureItemView}
				/>
			</div>
		</div>
	);
};

export default AllVentures;
