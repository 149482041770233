import React, { useState } from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Image from '../Image';

const nsBase = 'component';
const ns = `${ nsBase }-image-stack`;

const ImageStack = ({ items }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const [activeItem, setActiveItem] = useState(0);

	const triggerImageClick = (clickedIndex) => {
		if (window.innerWidth >= 768) {
			if (activeItem === clickedIndex) {
				if (activeItem >= items.length - 1) {
					setActiveItem(0);
				} else {
					setActiveItem(activeItem + 1);
				}
			} else {
				setActiveItem(clickedIndex);
			}
		}
	};

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__left`}>
				{items.map((item, index) => {
					const activeItemClass = index === activeItem ? 'active' : '';
					return (
						<div
							key={uniqueId('envoy')}
							className={`${ ns }__item-description ${ activeItemClass }`}
							data-index={index + 1}
						>
							{item.text}
							<br />
							{`${ activeItem + 1 } / ${ items.length }`}
						</div>
					);
				})}
			</div>
			<div className={`${ ns }__right`}>
				<div className={`${ ns }__items-wrapper`}>
					<div className={`${ ns }__items`}>
						{items.map((item, index) => {
							const activeItemClass = index === activeItem ? 'active' : '';
							return (
								<div // eslint-disable-line
									key={uniqueId('envoy')}
									className={`${ ns }__item ${ activeItemClass }`}
									data-index={index + 1}
									onClick={() => { triggerImageClick(index); }}
								>
									<div className={`${ ns }__item-image`}>
										<Image {...item.image} />
									</div>
									<div className={`${ ns }__item-description`}>
										{item.text}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageStack;
