import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import Button from '../Button';

const nsBase = 'component';
const ns = `${ nsBase }-filter`;

const FilterVentures = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }-ventures`]: true
	});

	const {
		title,
		filters,
		items,
		handleFilteredItems
	} = props;

	const [filterState, setFilterState] = useState({
		industry: 'all',
		investment: 'all'
	});

	const industrySelected = filterState.industry !== 'all';
	const investmentSelected = filterState.investment !== 'all';
	const filterSelected = industrySelected || investmentSelected;
	const filterSelectedClass = filterSelected ? 'filter-selected' : '';
	const targetElement = useRef();

	const handleResetState = () => {
		if (filterSelected) {
			const selects = document.querySelectorAll('select');
			[].forEach.call(selects, (select) => {
				select.value = 'all'; // eslint-disable-line
			});
			handleFilteredItems([...items]);
			setFilterState({
				industry: 'all',
				investment: 'all'
			});
		}
	};

	const filterItems = () => {
		const filteredItems = [];

		items.map((item) => {
			if (filterState.industry !== 'all' && filterState.investment !== 'all') {
				// Loop through industry to get matches then loop through investment type
				item.filter.industry.map((IndustryCategory) => {
					if (IndustryCategory === filterState.industry) {
						item.filter.investment.map((investmentCategory) => {
							if (investmentCategory === filterState.investment) {
								filteredItems.push(item);
							}
							return null;
						});
					}
					return null;
				});
			} else {
				// Loop through industry
				item.filter.industry.map((category) => {
					if (category === filterState.industry) {
						filteredItems.push(item);
					}
					return null;
				});

				// Loop through investment type
				item.filter.investment.map((category) => {
					if (category === filterState.investment) {
						filteredItems.push(item);
					}
					return null;
				});
			}
			return null;
		});

		handleFilteredItems([...filteredItems]);
	};

	const handleSelectChange = (e) => {
		const { name, value } = e.target;
		setFilterState({...filterState, [name]: value});
	};

	const renderFilterItems = (filterCategory) => {
		const filterOptions = [];

		items.map((item) => {
			if (item.filter[filterCategory]) {
				item.filter[filterCategory].map((category) => {
					filterOptions.push(category);
					return category;
				});
			}
			return null;
		});

		// Remove duplicate options
		const newFilters = Array.from(new Set(filterOptions));

		return (
			newFilters.map((filter) => {
				return (
					<option key={filter} value={filter}>{filter}</option>
				);
			})
		);
	};

	const renderFilters = () => {
		return filters.map((filterCategory) => {
			let filterTitle = '';
			let filterTitleClass = '';
			let filterTitlePlurals = '';

			switch (filterCategory) {
			case 'industry':
				filterTitle = industrySelected ? filterState.industry : 'By Industry';
				filterTitleClass = industrySelected ? 'active' : '';
				filterTitlePlurals = 'Industries';
				break;
			case 'investment':
				filterTitle = investmentSelected ? filterState.investment : 'By Investment Type';
				filterTitleClass = investmentSelected ? 'active' : '';
				filterTitlePlurals = 'Investment Types';
				break;
			default:
				break;
			}

			return (
				<div key={filterCategory} className={`${ ns }__category`}>
					<h2
						className={`${ ns }__category-title ${ filterCategory } ${ filterTitleClass }`}
					>
						{filterTitle}
					</h2>
					<select
						className={`${ ns }__category-select`}
						name={filterCategory}
						ref={targetElement}
						onChange={(e) => { handleSelectChange(e); }}
					>
						<option value={'all'}>{`All ${ filterTitlePlurals }`}</option>
						{renderFilterItems(filterCategory)}
					</select>
				</div>
			);
		});
	};

	useEffect(() => {
		if (filterState.industry !== filterState.investment) {
			filterItems();
		} else if (filterState.industry !== 'all' || filterState.investment !== 'all') {
			handleFilteredItems([...items]);
		}
	}, [filterState]); //eslint-disable-line

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__content ${ filterSelectedClass }`}>
					<h2 className={`${ ns }__title`}>
						<button onClick={(e) => { handleResetState(e); }}>{title}</button>
					</h2>
					<div className={`${ ns }__categories`}>
						{renderFilters()}
					</div>
					{filterSelected && (
						<div className={`${ ns }__clear`}>
							<Button
								variant={'white'}
								onClick={(e) => { handleResetState(e); }}
							>
								Clear
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FilterVentures;
