import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import ImageStack from '../components/ImageStack';
import AllVentures from '../components/AllVentures';

const nsBase = 'page';
const ns = `${ nsBase }-ventures`;

const VenturesPage = ({ data }) => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true
	});

	const {
		venturesJson: {
			hero,
			ventures
		}
	} = data;

	return (
		<div className={rootClassnames}>
			<SEO title={'Ventures'} />
			<Hero
				variant={'gray'}
				heading={hero.heading}
			>
				<ImageStack
					items={hero.imageStack}
				/>
			</Hero>
			<AllVentures ventures={ventures} />
		</div>
	);
};

export default VenturesPage;

export const query = graphql`
	query GET_PAGE_VENTURES {
		venturesJson {
			hero {
				heading,
				imageStack {
					text,
					image {
						alt_text,
						main_image
					},  
				}
			},
			ventures {
				name,
				name2,
				url,
				secondColumnLines,
				image {
					alt_text,
					main_image
				},
				filter {
					industry,
					investment
				}
			}
		}
	}
`;
